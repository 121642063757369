import React from "react";
import ContentfulClient from "../contentful-client";
import "./MusicList.scss";
import MusicListItem from "./music-list-item";
import CategoriesFilter from "../categories-filter";

const musicCategories = ["Parquet Courts", "A. Savage", "Wiccans", "Teenage Cool Kids", "Fergus & Geronimo", "Misc"]


class MusicList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { projects: [], currentFilters: [] };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    ContentfulClient.getEntries({
      content_type: "music",
      order: "-fields.date",
      limit: 999, // u must dial 999
    })
      .then((data) => {
        this.setState({ projects: data.items });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  filteredProjects() {
    if (this.state.currentFilters.length) {
      return this.state.projects.filter((project) => {
        return this.state.currentFilters
          .map((filter) => {
            console.log(project.fields.categories)
            return project?.fields?.categories?.includes(filter);
          })
          .includes(true);
      });
    } else {
      return this.state.projects;
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState((prevState) => {
      let newFilters = prevState.currentFilters;

      if (value === true) {
        newFilters.push(name);
      } else {
        newFilters.splice(newFilters.indexOf(name), 1);
      }
      return { currentFilters: newFilters };
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="music-list-wrapper">
          <ul className="music-list">
            {this.filteredProjects().map((project) => {
              return (
                <MusicListItem
                  key={project.sys.id}
                  musicProject={project}
                  handleHoverImage={this.props.handleHoverImage}
                />
              );
            })}
          </ul>
        </div>
        <CategoriesFilter
          categories={musicCategories}
          handleInputChange={this.handleInputChange}
          currentFilters={this.state.currentFilters}
        />
      </React.Fragment>
    );
  }
}

export default MusicList;

