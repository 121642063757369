import React from "react";
import "./HoverImage.scss";

class HoverImage extends React.Component {
  render() {
    let hoverImage = this.props.hoverImage;
    return (
      <div
        className={`hover-image ${hoverImage.placement.vertical} ${hoverImage.placement.horizontal}`}
      >
        <img
          sizes="25vw"
          src={`${hoverImage.image.fields.file.url}?w=768&q=80`}
          srcSet={`${hoverImage.image.fields.file.url}?w=768&q=80 768w, ${hoverImage.image.fields.file.url}?w=1152&q=80 1152w,  ${hoverImage.image.fields.file.url}?w=1536&q=80 1536w, ${hoverImage.image.fields.file.url}?w=1536&q=80 2034w`}
          width={hoverImage.image.fields.file.details.image.width}
          height={hoverImage.image.fields.file.details.image.height}
        />
      </div>
    );
  }
}

export default HoverImage;
