import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import OpenedState from "../menu-item/openedState";
import ClosedState from "../menu-item/closedState";
import ProjectsList from "../projects-list";
import AboutPage from "../about-page";
import JournalList from "../journal-list";
import MusicList from "../music-list";
import "./Menu.scss";
import "../menu-item/MenuItem.scss";
import CalendarPage from "../calendar-page";


const Menu = (props) => {
  const location = useLocation()

  const menuItems = {
    portfolio: { path: "portfolio", name: "Portfolio", component: <ProjectsList handleHoverImage={props.handleHoverImage} /> },
    music: { path: "music", name: "Music", component: <MusicList handleHoverImage={props.handleHoverImage} /> },
    calendar: { path: "calendar", name: "Calendar", component: <CalendarPage handleHoverImage={props.handleHoverImage} /> },
    about: { path: "about", name: "About", component: <AboutPage handleHoverImage={props.handleHoverImage} /> },
    journal: { path: "journal", name: "Journal", component: <JournalList handleHoverImage={props.handleHoverImage} /> },
  }


  return <nav className="main-menu" role="navigation">
    {Object.keys(menuItems).map((name) => {

      return (
        <Routes
          key={name}
          primary={false}
        >
          <Route
            path="/"
            element={<ClosedState
              className={`menu-item menu-item--${name} menu-item--closed circle-link-container`}
              handleHoverImage={props.handleHoverImage}
              key={name + "closed"}
              name={menuItems[name].name}
              to={menuItems[name].path}
            />}
          />
          <Route
            path={menuItems[name].path + "/*"}
            element={<OpenedState
              className={`menu-item menu-item--${name} expanded`}
              key={name + "opened"}
              name={menuItems[name].name}
              expanded
            >{menuItems[name].component}</OpenedState>}
          />
        </Routes>
      );
    })}
  </nav>
}

export default Menu;
