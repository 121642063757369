import React from "react";
import "./DefaultHeading.scss";

class DefaultHeading extends React.PureComponent {
  render() {
    return (
      <h1 className="default-heading indented margin-0">{this.props.header}</h1>
    );
  }
}

export default DefaultHeading;
