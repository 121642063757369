import React, { useState, useEffect } from "react";
import ContentfulClient from "../contentful-client";
import ContentHeading from "../content-heading";
import ProjectImage from "../project-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./JournalEntry.scss";
import { useParams } from "react-router";

const contentfulRenderOptions = {
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

const renderContentSection = (contentSection) => {
  if (contentSection.sys.contentType.sys.id === "projectImage") {
    return (
      <ProjectImage key={contentSection.sys.id} image={contentSection} />
    );
  } else if (contentSection.sys.contentType.sys.id === "richTextBlock") {
    return (
      <div className="col-xs-12" key={contentSection.sys.id}>
        {documentToReactComponents(
          contentSection.fields.text,
          contentfulRenderOptions
        )}
      </div>
    );
  }
}

const JournalEntry = (props) => {
  const params = useParams()
  const [entry, setEntry] = useState(null)

  useEffect(() => {
    ContentfulClient.getEntries({
      content_type: "journalEntry",
      "fields.slug": params.journalSlug,
    }).then((response) => {
      setEntry(response.items[0]);
    }).catch((err) => console.error(err))
  }, [params])


    return (
      <div className="journal-container">
        {entry && (
          <ContentHeading
            title={entry.fields.title}
            subtitle={entry.fields.subtitle}
            closeLink="/journal"
          />
        )}
        <div className="container-fluid journal-entry fade-in">
          <div className="row">
            {entry &&
              entry.fields.contentSections.map((contentSection) => {
                return renderContentSection(contentSection);
              })}
          </div>
        </div>
      </div>
    );

}

export default JournalEntry;
