import React from "react";
import "./Footer.scss";
import NewsletterSignup from "../newsletter-signup";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewsletterSignup: false,
    };
    this.toggleNewsletterSignup = this.toggleNewsletterSignup.bind(this);
  }

  toggleNewsletterSignup() {
    this.setState((prevState) => {
      return { showNewsletterSignup: !prevState.showNewsletterSignup };
    });
  }

  render() {
    return (
      <footer>
        <div className="footer__text">
          <h3>
            <a href="mailto:savage@a-savage.com">Email</a>
          </h3>
          <h3>
            <span role="button" onClick={this.toggleNewsletterSignup}>Newsletter</span>
          </h3>
        </div>
        {this.state.showNewsletterSignup && <NewsletterSignup />}
      </footer>
    );
  }
}

export default Footer;
