import React from "react";
import CircleLink from "../circle-link";
import { useNavigate } from "react-router";

const ClosedState = (props) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(props.to);
  }

  return (
    <div className={props.className} onClick={handleClick}>
      <CircleLink to={props.to} name={props.name} />
    </div>
  );
}

export default ClosedState;
