import React from "react";
import "./CategorySelector.scss";

class CategorySelector extends React.Component {
  render() {
    return (
      <div className="category-selector">
        <input
          name={this.props.category}
          type="checkbox"
          checked={this.props.checked}
          onChange={this.props.handleInputChange}
          id={`${this.props.category}-checkbox`}
        />
        <label htmlFor={`${this.props.category}-checkbox`}>
          <div className="circle-check"></div>
          {this.props.category}
        </label>
      </div>
    );
  }
}

export default CategorySelector;
