import React from "react";
import ContentfulClient from "../contentful-client";
import "./JournalList.scss";
import JournalListItem from "./journal-list-item";

class JournalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { journalEntries: [] };
  }

  componentDidMount() {
    ContentfulClient.getEntries({
      content_type: "journalEntry",
      order: "-fields.date",
      limit: 999, // u must dial 999
    })
      .then((data) => {
        this.setState({ journalEntries: data.items });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <ul className="journal-list">
        {this.state.journalEntries.map((journalEntry) => {
          return (
            <JournalListItem
              key={journalEntry.sys.id}
              journalEntry={journalEntry}
              handleHoverImage={this.props.handleHoverImage}
            />
          );
        })}
      </ul>
    );
  }
}

export default JournalList;
