import React from "react";
import CircleLink from "../circle-link";

class ProjectsListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleMouseOver() {
    this.props.handleHoverImage({
      image: this.props.project.fields.hoverImage,
      placement: {
        vertical: this.props.project.fields.hoverImageVerticalPlacement,
        horizontal: this.props.project.fields.hoverImageHorizontalPlacement,
      },
    });
  }

  handleMouseOut() {
    this.props.handleHoverImage(null);
  }

  render() {
    return (
      <li
        key={`menu-item-${this.props.project.sys.id}`}
        className="projects-list-item circle-link-container"
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        <CircleLink
          to={`/portfolio/${this.props.project.fields.slug}`}
          name={this.props.project.fields.title}
        />
      </li>
    );
  }
}

export default ProjectsListItem;
