import React from "react";
import jsonp from "jsonp";
import queryString from "query-string";

class NewsletterSignup extends React.Component {
  constructor(props) {
    super(props);
    this.mailchimpURL =
      "//bandcamp.us7.list-manage.com/subscribe/post-json?u=1ae409d21721e35bc63cb9caf&amp;id=89a5d71aed";

    this.state = {
      value: "",
      email: "",
      submitting: false,
      result: null,
      message: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendToMailchimp = this.sendToMailchimp.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState((prevState) => {
      return {
        email: prevState.value,
        submitting: true,
        value: "Submitting...",
      };
    }, this.sendToMailchimp);
  }

  sendToMailchimp() {
    const formData = queryString.stringify({
      EMAIL: this.state.email,
    });

    jsonp(`${this.mailchimpURL}&${formData}`, { param: "c" }, (err, data) => {
      console.error(err);
      console.log(data);
      if (data.result === "success") {
        this.setState({
          value: "Subscribed",
        });
      }
      if (data.result === "error") {
        const message = /already subscribed/i.test(data.msg)
          ? "Already Subscribed"
          : /Please enter a value/i.test(data.msg)
          ? "Please enter an email"
          : data.msg;

        this.setState({ value: message, submitting: false });
      }
    });
  }

  render() {
    return (
      <form className="mailchimp-subscribe-form" onSubmit={this.handleSubmit}>
        <input
          type="email"
          placeholder="Your Email"
          value={this.state.value}
          onChange={this.handleChange}
        ></input>
        <button
          className="email-submit-button"
          type="submit"
          value="Submit"
          disabled={this.state.submitting}
        >
          Submit
        </button>
      </form>
    );
  }
}

export default NewsletterSignup;
