import vhCheck from "vh-check";
import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./components/app";
import "./styles/styles.scss";

const mountNode = document.getElementById("mount-point");
const root = createRoot(mountNode);

const vhCheckResults = vhCheck({ force: true });

// dumbest hack ever. i TRULY hate iOS safari.
if (vhCheckResults.isNeeded) {
  window.timesOrientationHasChanged = 0;
  window.addEventListener("orientationchange", () => {
    if (window.timesOrientationHasChanged < 1) {
      const body = document.getElementsByTagName("BODY")[0];
      body.style.display = "none";
      setTimeout(function () {
        body.style.display = "block";
        vhCheck({
          force: true,
        });
      }, 250);
    }
    window.timesOrientationHasChanged++;
  });
}

console.log(JSON.stringify(vhCheckResults));

root.render(<App />);
