import { createClient } from "contentful";


const contentfulOptions = {}

contentfulOptions.space = "wes1vt7sf9ho";

if (typeof process.env.REACT_APP_PREVIEW_TOKEN !== "undefined") {
  contentfulOptions.accessToken = process.env.REACT_APP_PREVIEW_TOKEN;
  contentfulOptions.host = "preview.contentful.com";
} else {
  contentfulOptions.accessToken = "MoYyBG2l15GjMqt0WttZ9Ze7e3GyEHqHoIM0FSn2jAs"; // public delivery token
}

console.log("Preview token:", process.env.REACT_APP_PREVIEW_TOKEN);

const ContentfulClient = createClient(contentfulOptions);

export default ContentfulClient;
