import React from "react";
import ContentfulClient from "../contentful-client";
import DefaultHeading from "../default-heading";
import HomepageImage from "../homepage-image";
import HoverImage from "../hover-image";
import Footer from "../footer";
import "./DefaultView.scss";
import isEmpty from "../../helpers/is-empty";

class DefaultView extends React.Component {
  constructor(props) {
    super(props);
    this.entryId = "21LHKWNaNOvhWd10Rsbs0y";
    this.state = { entry: { fields: { homepageImage: {} } } };
    this.homepageImage = this.homepageImage.bind(this);
  }

  homepageImage() {
    const homepageImage = this.state.entry.fields.homepageImage;
    if (typeof homepageImage !== "undefined" && !isEmpty(homepageImage)) {
      return homepageImage;
    }
    return false;
  }

  componentDidMount() {
    ContentfulClient.getEntry(this.entryId).then((data) => {
      this.setState({ entry: data });
    });
  }

  render() {
    const homepageImage = this.homepageImage();
    return (
      <div className="default-view">
        <DefaultHeading header={this.props.header} />
        {this.props.parentLocation.pathname === "/" && homepageImage && (
          <HomepageImage photo={homepageImage} />
        )}
        {this.props.hoverImage && (
          <HoverImage
            hoverImage={this.props.hoverImage}
            key={this.props.hoverImage.image.sys.id}
          />
        )}
        <Footer />
      </div>
    );
  }
}

export default DefaultView;
