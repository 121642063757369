const YouTubeEmbed = (props) => {
  let videoId;
  const youTubeUrl = new URL(props.video.fields.youtubeUrl)

  if (youTubeUrl.host === "youtu.be") {
    // shortened youtube URL like https://youtu.be/NDWgtB_MD24
    videoId = youTubeUrl.pathname.split("/")[1]
  } else {
    // expecting like: https://www.youtube.com/watch?v=NDWgtB_MD24
    videoId = youTubeUrl.searchParams.get("v")
  }

  return <div className="col-xs-12">
    <div className="youtube-embed-container">
      <iframe
        className="youtube-embed"
        src={`https://www.youtube-nocookie.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen ></iframe >
    </div>
  </div>
}

export default YouTubeEmbed
