import React from "react";
import { Link } from "react-router-dom";
import "./CircleLink.scss";

const CircleLink = (props) => {
    return (
      <div className="circle-link">
        <Link className="circle-link__link" to={props.to}>
          {props.name}
          {props.children}
        </Link>
      </div>
    );
}

export default CircleLink;
