import React from "react";
import { Link } from "react-router-dom";
import "./MenuButton.scss";

const MenuButton = (props) => {
  return (
    <Link to="/" className="menu-button" onClick={props.handleMenuClose}>
      <h3>MENU</h3>
    </Link>
  );
}

export default MenuButton;
