import React from "react";
import "./ResponsiveImage.scss";

class ResponsiveImage extends React.Component {
  render() {
    const responsiveImage = this.props.photo;
    const baseURL = responsiveImage.fields.image.fields.file.url;
    const caption = responsiveImage.fields.caption;
    const sizes = this.props.sizes || "(orientation: landscape) 66vw, 100vw"
    return (
      <figure className="responsive-image" key={`${responsiveImage.sys.id}`}>
        {/* // TODO: fix this -- multiple sizes being downloaded in safari. maybe use picture element? */}
        <img
          src={`${baseURL}?w=768&q=80`}
          srcSet={`${baseURL}?w=768&q=90 768w,
          ${baseURL}?w=992&q=90 992w,
          ${baseURL}?w=1200&q=90 1200w,
          ${baseURL}?w=1536&q=90 1536w,
          ${baseURL}?w=1984&q=90 1984w,
          ${baseURL}?w=2400&q=90 2400w,
          ${baseURL}?w=2880&q=90 2880w`}
          sizes={sizes}
          alt={caption && `${caption}`}
          width={`${responsiveImage.fields.image.fields.file.details.image.width}`}
          height={`${responsiveImage.fields.image.fields.file.details.image.height}`}
        ></img>
        {caption && <figcaption>{`${caption}`}</figcaption>}
      </figure>
    );
  }
}

export default ResponsiveImage;
