import React from "react";
import { Link } from "react-router-dom";
import "./close-button.scss";

class CloseButton extends React.Component {
  render() {
    return (
      <Link className="close-button fade-in" to={this.props.to || "/"}>
        <h3>CLOSE</h3>
      </Link>
    );
  }
}
export default CloseButton;
