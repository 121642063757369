import React from "react";
import { Routes, Route } from "react-router-dom"
import MediaQuery from "react-responsive";
import "./CategoriesFilter.scss";
import CategorySelector from "../category-selector";

class CategoriesRow extends React.Component {
  render() {
    return (
      <div className="categories-filter-row">
        {this.props.categories.map((category, index) => {
          return (
            <CategorySelector
              key={index}
              checked={this.props.currentFilters.includes(category)}
              category={category}
              handleInputChange={this.props.handleInputChange}
            />
          );
        })}
      </div>
    );
  }
}

class CategoriesColumn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: !!props.currentFilters.length,
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }
  // expanded rules:
  // if you have a category checked.
  // if you clicked expanded

  toggleExpanded() {
    this.setState((prevState) => {
      return {
        expanded: !prevState.expanded,
      };
    });
  }

  render() {
    return (
      <div className="categories-filter-column">
        <button
          className="categories-toggle-button"
          onClick={this.toggleExpanded}
        >
          Categories
          <span
            className={`categories-toggle-button__caret ${
              this.state.expanded ? "expanded" : ""
            }`}
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 12 12"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon points="0,12 6,6 12,12" />
            </svg>
          </span>
        </button>
        <div
          className={`categories-filter-animated-container ${
            this.state.expanded ? "expanded" : ""
          }`}
        >
          {this.state.expanded &&
            this.props.categories.map((category, index) => {
              return (
                <CategorySelector
                  key={index}
                  checked={this.props.currentFilters.includes(category)}
                  category={category}
                  handleInputChange={this.props.handleInputChange}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

class CategoriesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    return (
      <div>
        <MediaQuery maxWidth="948px">
          <CategoriesColumn
            path=":projectSlug"
            currentFilters={this.props.currentFilters}
            categories={this.props.categories}
            handleInputChange={this.props.handleInputChange}
            key="column"
          />
        </MediaQuery>
        <MediaQuery minWidth="949px">
          <Routes>
            <Route
              path=":projectSlug"
              element={
                <CategoriesColumn
                  currentFilters={this.props.currentFilters}
                  categories={this.props.categories}
                  handleInputChange={this.props.handleInputChange}
                  key="column"
                />
              }
            />
            <Route
              path="*"
              element={<CategoriesRow
              currentFilters={this.props.currentFilters}
                categories={this.props.categories}
              handleInputChange={this.props.handleInputChange}
              key="row"
              />}
            />
          </Routes>
        </MediaQuery>
      </div>
    );
  }
}

export default CategoriesFilter;
