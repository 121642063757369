import React, { useState } from "react";
import ContentContainer from "../content-container";
import Menu from "../menu";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  const [hoverImage, setHoverImage] = useState(null)

  const handleHoverImage = (hoverImage) => {
    setHoverImage(hoverImage);
  }

  return (
    <BrowserRouter>
      <div className="app">
        <ContentContainer hoverImage={hoverImage} />
        <Menu handleHoverImage={handleHoverImage} />
      </div>
    </BrowserRouter>
  )
}

export default App;
