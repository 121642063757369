import React, { useEffect, useState } from "react";
import ContentfulClient from "../contentful-client";
import ContentHeading from "../content-heading";
import ProjectImage from "../project-image";
import Description from "../description";
import { useParams, useLocation } from "react-router";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import YouTubeEmbed from "../youtube-embed";
import "./Project.scss";

export const contentfulRenderOptions = {
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

const Project = (props) => {
  const params = useParams();
  const location = useLocation()
  const [entry, setEntry] = useState({ fields: { projectImages: [], secondaryInfo: false } })

  useEffect(() => {
    ContentfulClient.getEntries({
      content_type: props.type || "project",
      "fields.slug": params.projectSlug,
    })
      .then((response) => {
        setEntry(response.items[0]);
      })
      .catch((err) => console.error(err));
  }, [params, location]) 

  return (
    <div className="project-container fade-in">
      <ContentHeading
        title={entry.fields.title}
        subtitle={entry.fields.subtitle}
        closeLink={props.type == "project" ? "/portfolio" : "/music"}
      />
      <div className="project container-fluid">
        {entry.fields.secondaryInfo && (
          <div className="row">
            <div className="col-xs-12 secondary-info">
              {documentToReactComponents(
                entry.fields.secondaryInfo,
                contentfulRenderOptions
              )}
            </div>
          </div>
        )}
        {entry.fields.projectImages.length > 0 && (
          <div className="row">
            {entry.fields.projectImages.map((imageOrVideo) => {
              const contentType = imageOrVideo.sys.contentType.sys.id
              if (contentType === "projectImage") {
                return <ProjectImage key={imageOrVideo.sys.id} image={imageOrVideo} />;
              }
              if (contentType === "youTubeEmbed") {
                return <YouTubeEmbed key={imageOrVideo.sys.id} video={imageOrVideo} />
              }
            })}
          </div>
        )}
        {typeof entry.fields.description !== "undefined" && (
          <Description
            description={entry.fields.description}
            contentfulRenderOptions={contentfulRenderOptions}
          />
        )}
      </div>
    </div>
  );
}

export default Project;
