import React from "react";
import MenuButton from "../menu-button";

const OpenedState = (props) => {
  return (
    <div className={props.className}>
      <div className="menu-item__sticky-header menu-item__sticky-header--closed">
        <h2 className="margin-0">{props.name}</h2>
        <MenuButton handleMenuClose={props.handleMenuClose} />
      </div>
      {props.children}
    </div>
  );
}

export default OpenedState;
