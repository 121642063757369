import React from "react";
import CloseButton from "../close-button";
import "./ContentHeading.scss";

class ContentHeading extends React.Component {
  render() {
    return (
      <div className="content-heading">
        <h2 className="title">{this.props.title}</h2>
        <CloseButton to={this.props.closeLink} />
      </div>
    );
  }
}

export default ContentHeading;
