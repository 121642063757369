import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./Description.scss";

class Description extends React.Component {
  render() {
    return (
      <div className="description">
        <div>
          <h2 className="description__heading">Description</h2>
        </div>
        <div className="description__body">
          {documentToReactComponents(
            this.props.description,
            this.props.contentfulRenderOptions
          )}
        </div>
      </div>
    );
  }
}

export default Description;
