import React from "react";
import "./HomepageImage.scss";

class HomepageImage extends React.Component {
  render() {
    const responsiveImage = this.props.photo;
    if (!responsiveImage) return null
    const baseURL = responsiveImage?.fields?.image?.fields?.file?.url;
    const caption = responsiveImage?.fields?.caption;

    return (
      <div className="homepage-image-container">
        <div className="homepage-image-grid-container container-fluid">
          <div
            className={`homepage-image-row col-xs-${responsiveImage?.fields?.widthInColumns} col-xs-offset-${responsiveImage?.fields.offsetInColumns}`}
          >
            <img
              className="homepage-image"
              src={`${baseURL}?w=768&q=80`}
              srcSet={`${baseURL}?w=768&q=90 768w,
              ${baseURL}?w=992&q=90 992w,
              ${baseURL}?w=1200&q=90 1200w,
              ${baseURL}?w=1536&q=90 1536w,
              ${baseURL}?w=1984&q=90 1984w,
              ${baseURL}?w=2400&q=90 2400w,
              ${baseURL}?w=2880&q=90 2880w`}
              sizes="(orientation: landscape) 66vw, 100vw"
              alt={caption && `${caption}`}
              // width={`${responsiveImage.fields.image.fields.file.details.image.width}`}
              // height={`${responsiveImage.fields.image.fields.file.details.image.height}`}
            ></img>
          </div>
        </div>
      </div>
    );
  }
}

export default HomepageImage;
