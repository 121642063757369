import React, { useEffect, useState } from "react";
import "./ContentContainer.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import AboutImage from "../about-image";
import Project from "../project";
import JournalEntry from "../journal-entry";
import DefaultView from "../default-view";
import ContentfulClient from "../contentful-client";
import CalendarImage from "../calendar-image";
const siteWideHeaderEntryId = "1REajFtLUbTslniVaM07l6";

const ContentContainer = (props) => {
  const [siteWideHeader, setSiteWideHeader] = useState({ fields: { header: "" } })
  const location = useLocation()

  useEffect(() => {
    ContentfulClient.getEntry(siteWideHeaderEntryId).then((data) => {
      setSiteWideHeader(data);
    });
  }, [])

  const shouldBeExpanded = (location) => {
    return !!location.pathname.match(/(portfolio|journal|music)\/\w/i);
  }


  return (
    <main
      className={`content-container ${shouldBeExpanded(location)
        ? "content-container--expanded"
        : ""
        }`}
    >
      <Routes>
        <Route path="*"
          element={<DefaultView
            header={siteWideHeader.fields.header}
            hoverImage={props.hoverImage}
            parentLocation={location}
          />}
        />
        <Route
          path="/portfolio/:projectSlug"
          element={<Project type="project" />} />
        <Route
          path="/music/:projectSlug"
          element={<Project type="music" />}
        />
        <Route
          path="/about"
          element={<AboutImage className="about-image" />}
        />
        <Route
          path="/calendar/*"
          element={<CalendarImage className="calendar-image" />}
        />
        <Route
          path="/journal/:journalSlug"
          element={<JournalEntry />}
        />
      </Routes>
    </main>
  )
}

export default ContentContainer;
