import React from "react";
import ResponsiveImage from "../responsive-image";
// import "./ProjectImage.scss";

class ProjectImage extends React.Component {
  render() {
    let image = this.props.image;
    return (
      <div
        className={`col-xs-12 col-lg-${image.fields.widthInColumns} col-lg-offset-${image.fields.offsetInColumns}`}
        key={image.sys.id}
      >
        <ResponsiveImage photo={image} />
      </div>
    );
  }
}

export default ProjectImage;
