import React from "react";
import CircleLink from "../circle-link";

const MusicListItem = (props) => {

  const handleMouseOver = () => {
    props.handleHoverImage({
      image: props.musicProject.fields.hoverImage,
      placement: {
        vertical: props.musicProject.fields.hoverImageVerticalPlacement,
        horizontal: props.musicProject.fields.hoverImageHorizontalPlacement,
      },
    });
  }

  const handleMouseOut = () => {
    props.handleHoverImage(null);
  }


  return (
    <li
      key={`menu-item-${props.musicProject.sys.id}`}
      className="music-list-item circle-link-container"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <CircleLink
        to={`/music/${props.musicProject.fields.slug}`}
        name={props.musicProject.fields.title}
      />
    </li>
  );

}

export default MusicListItem;
