import React from "react";
import "./CalendarPage.scss";
import ContentfulClient from "../contentful-client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

class CalendarPage extends React.Component {
  constructor(props) {
    super(props);
    this.entryId = "5aCWmNDKdmB77D2ZGkSeko";
    this.state = { entry: { fields: { content: null, image: {} } } };
    this.contentfulRenderOptions = {
      renderText: (text) =>
        text
          .replace(/\u2028/g, "")
          .split("\n")
          .flatMap((text, i) => [i > 0 && <br />, text]),
    };
  }

  componentDidMount() {
    ContentfulClient.getEntry(this.entryId).then((data) =>
      this.setState({ entry: data })
    );
  }

  render() {
    return (
      <div className="calendar-page fade-in">
        {this.state.entry.fields.content &&
          documentToReactComponents(
            this.state.entry.fields.content,
            this.contentfulRenderOptions
          )}
      </div>
    );
  }
}

export default CalendarPage;
