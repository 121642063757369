import React from "react";
import "./CalendarImage.scss";
import ContentfulClient from "../contentful-client";
import DefaultHeading from "../default-heading";
import Footer from "../footer";

class CalendarImage extends React.Component {
  constructor(props) {
    super(props);
    this.entryId = "5aCWmNDKdmB77D2ZGkSeko";
    this.state = { entry: { fields: { image: null } } };
  }
  render() {
    return (
      <div className="about-image-container">
        <DefaultHeading header={this.state.entry.fields.header} />
        <div className="about-image">
          {this.state.entry.fields.image && (
            <img src={this.state.entry.fields.image.fields.file.url} />
          )}
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
    ContentfulClient.getEntry(this.entryId).then((data) =>
      this.setState({ entry: data })
    );
  }
}

export default CalendarImage;
