import React from "react";
import CircleLink from "../circle-link";

class JournalListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleMouseOver() {
    this.props.handleHoverImage({
      image: this.props.journalEntry.fields.hoverImage,
      placement: {
        vertical: this.props.journalEntry.fields.hoverImageVerticalPlacement,
        horizontal: this.props.journalEntry.fields
          .hoverImageHorizontalPlacement,
      },
    });
  }

  handleMouseOut() {
    this.props.handleHoverImage(null);
  }

  render() {
    return (
      <li
        className="journal-list-item circle-link-container"
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        <CircleLink to={`/journal/${this.props.journalEntry.fields.slug}`}>
          <div className="journal-list-item__moving-block">
            <div className="journal-list-item__date">
              {this.props.journalEntry.fields?.date?.replace(/-/g, ".")}
            </div>
            <br />
            <div className="journal-list-item__title">
              {this.props.journalEntry.fields.title}
            </div>
          </div>
        </CircleLink>
      </li>
    );
  }
}

export default JournalListItem;
